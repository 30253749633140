import { models } from 'powerbi-client';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { JustificationService, InteractionService, ToastService } from '@app/services';
import { Justification } from '@app/models';
import { JustificationType } from "../../../../_models/enum-justificaton";

@Component({
  selector: 'evo-contestation-declined-button',
  templateUrl: './contestation-declined-button.component.html',
  styleUrls: ['./contestation-declined-button.component.scss']
})
export class ContestationDeclinedButtonComponent extends InteractionContestationButton implements OnInit {

	public justifications: Justification[];

  constructor(ngbModal: NgbModal, protected activeModal: NgbActiveModal, toastService: ToastService, formBuilder: FormBuilder, public interactionService: InteractionService, public justificationService: JustificationService) {
	  super(ngbModal, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {
		this.getJustifications();
	}

	private getJustifications(): void {
		this.justificationService.findAll().subscribe(data => {
			this.justifications = data.filter(justification => justification.type === JustificationType.CONTEST_DECLINED);
		});
	}
}
