import { FormGroup } from '@angular/forms';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { JustificationType } from './enum-justificaton';

export class Justification implements Deserializable {
    id: number;
    text: string;
	type: JustificationType;
    customer: Customer;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

}
