import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EvoAIModels, Interaction, OrganizationAiModel, QualityPermissions } from "@app/models";
import { OrganizationService, UserService } from "@app/services";
import { GenAiChatService } from "../../_services/gen-ai-chat.service";
import { GenAiPromptFilter, GenAiPromptRequest } from "../../_models/gen-ai-prompt.request";
import { GenAiIntegratorService } from "../../_services/gen-ai-integrator.service";
import { environment } from "../../../environments/environment";

@Component({
	selector: 'app-ai-chat',
	templateUrl: 'ai-chat.component.html',
	styleUrls: ['ai-chat.component.scss']
})
export class AiChatComponent implements OnInit {
	@Input() public filters: FormGroup;
	@Input() public showFilter: boolean = true;
	@Input() public showTitle: boolean = true;
	@Input() public interaction: Interaction;
	@Input() public genAiTips: any[] = [];

	public organizationAiModels: OrganizationAiModel[] = [];
	public selectedOrganizationAiModel: OrganizationAiModel;

	public genAiTipsColors: string[] = ['text-success', 'text-danger', 'text-warning', 'text-primary'];

	public showFiltersBadge: boolean = true;

	public chatForm: FormGroup;

	public messages: { text: string, sender: string}[] = [];
	public loading: boolean = false;

	protected readonly qualityPermissions = QualityPermissions;

	public defaultTips: { title: string, prompt: string, icon: string }[] = [
		{
			title: 'Motivos de não pagamento',
			prompt: 'Com base na análise das transcrições de ligações de cobrança, quais são os cinco principais motivos mencionados pelos clientes para o não pagamento das suas dívidas?',
			icon: 'fas fa-hand-holding-usd',
		},
		{
			title: 'Motivos de insatisfação do cliente',
			prompt: 'Com base na análise das transcrições de ligações, quais são os cinco principais motivos que estão gerando insatisfações para os clientes?',
			icon: 'fas fa-frown',
		},
		{
			title: 'Resolutividade do agente',
			prompt: 'Com base na análise das transcrições de ligações, quais são os cinco principais motivos de não resolutividade da solicitação do cliente no atendimento?',
			icon: 'fas fa-question',
		},
		{
			title: 'Produtos Vendidos',
			prompt: 'Com base na análise das transcrições de ligações, quais são os produtos mais vendidos aos clientes?',
			icon: 'fas fa-shopping-cart',
		}
	];

	@Input() public showGenAiTips: boolean = true;

	@ViewChild('textArea') textArea!: ElementRef;
	@ViewChild('chatBox') private chatBox!: ElementRef;

	public errorMessage: string = 'Ops! Parece que sua solicitação é um pouco complexa para processarmos neste momento. Para obter os melhores resultados, sugerimos:\n' +
		'Simplificar sua pergunta, focando nos aspectos mais importantes.\n' +
		'Dividir sua consulta em partes menores, se possível.\n' +
		'Tentar reformular a pergunta de uma maneira diferente.\n' +
		'Se precisar de ajuda adicional, nossa equipe de suporte está à disposição. Estamos aqui para garantir que você obtenha as informações que precisa!'

	public constructor(private fb: FormBuilder,
	                   private organizationService: OrganizationService,
	                   private genAiChatService: GenAiChatService,
					   private genAiIntegratorService: GenAiIntegratorService,
	                   public userService: UserService) {

		this.chatForm = this.fb.group({
			message: ['', [Validators.required, Validators.minLength(1)]]
		});
	}

	ngOnInit(): void {
		let localStorageIndex = this.organizationService.currentOrganization.alias + '.' + this.interaction?.id;

		if(localStorage.getItem(localStorageIndex) != null) {
			this.messages = JSON.parse(localStorage.getItem(localStorageIndex) as string);

			if(this.messages[0].sender == 'system') {
				this.messages = this.messages.reverse();
				this.updateLocalStorage();
			}
		}

		this.getOrganizationAiModels();
		setTimeout(() => this.scrollToBottom(), 100);
	}

	public getOrganizationAiModels(): void {
		this.genAiIntegratorService.findByOrganizationAlias().subscribe(
			data => {
				this.organizationAiModels = data;
				this.selectedOrganizationAiModel = data[0];
				this.getGenAiTips();
			}
		);
	}

	public changeOrganizationAiModel(organizationAiModel: OrganizationAiModel): void {
		this.selectedOrganizationAiModel = organizationAiModel;
		this.getGenAiTips();
	}

	public getGenAiTips(): void {
		this.genAiIntegratorService.getGenAiTips(this.selectedOrganizationAiModel.id).subscribe(
			data => {
				this.genAiTips = data;
			}, error => {
				console.error(error);
				this.genAiTips = this.defaultTips;
			}
		);
	}

	public sendTipMessage(tip: any): void {
		this.chatForm.get('message')?.setValue(tip.prompt);
		this.sendMessage();
	}

	public sendMessage(): void {
		let message = { text: this.chatForm.get('message')?.value, sender: 'user' };
		this.messages.push(message);
		this.updateLocalStorage();

		this.loading = true;
		setTimeout(() => this.scrollToBottom(), 100);

		// clone message
		let clonedMessage = JSON.parse(JSON.stringify(message));

		let genAiPromptRequest: GenAiPromptRequest = {
			prompt: clonedMessage.text + ' | Responda utilizando markdown para formatar a resposta.',
			modelCode: this.filters.get('aiModelCode').value,
			filters: []
		};

		genAiPromptRequest.filters = this.completePromptRequest();
		this.chatForm.reset();

		this.textArea.nativeElement.style.height = `38px`;
		this.textArea.nativeElement.rows = 1;

		if(this.interaction) {
			genAiPromptRequest.prompt += ' | Responda com base na transcrição a seguir: ' + this.interaction.transcription.transcriptionAll;

			this.genAiChatService.askQuestion(genAiPromptRequest).subscribe(
				data => {
					this.messages.push({ text: data, sender: 'system' });
					this.updateLocalStorage();
				}, error => {
					this.messages.push({ text: 'Desculpe, não foi possível concluir a solicitação, tente novamente mais tarde.', sender: 'system' });
					this.updateLocalStorage();
				}
			).add(() => { this.loading = false; });
			return;
		}

		// if(this.filters.get('aiModelCode').value === EvoAIModels.AWS_CLAUDE_HAIKU_V3_5) {
		// 	clonedMessage.text += ' | Não responda sobre assuntos não relacionado a sua base de conhecimento, caso perguntem diga que você não pode responder sobre esses assuntos.';
		// }

		this.genAiChatService.sendPromptToKnowledgeBase(genAiPromptRequest, this.selectedOrganizationAiModel.id).subscribe(
			data => {
				if(data == 'Sorry, I am unable to assist you with this request.') {
					data = 'Desculpe, não consigo te ajudar com essa solicitação.';
				}

				this.messages.push({ text: data, sender: 'system' });
				this.updateLocalStorage();
			}, error => {
				this.messages.push({ text: this.errorMessage, sender: 'system' });
				this.updateLocalStorage();
			}
		).add(() => { this.loading = false });
	}

	private completePromptRequest(): GenAiPromptFilter[] {

		let genAiFilters: GenAiPromptFilter[] = [];

		if(this.hasFilter('segments')) {
			let segments = this.filters.get('segments').value;

			if(segments && segments.length > 0) {
				let segmentFilter: GenAiPromptFilter = {
					field: 'environmentId',
					value: [],
					operator: 'IN'
				};

				segments.forEach(id => {
					if(this.segmentData(id).speechEnvironmentId) {
						segmentFilter.value.push(this.segmentData(id).speechEnvironmentId);
					}
				});

				genAiFilters = [...genAiFilters, segmentFilter];
			}
		}

		if(this.hasFilter('operations')) {
			let operations = this.filters.get('operations').value;

			if(operations && operations.length > 0) {
				let operationsFilter: GenAiPromptFilter = {
					field: 'segmentName',
					value: [],
					operator: 'IN'
				};

				operations.forEach(id => {
					operationsFilter.value.push(this.operationData(id).name);
				});

				genAiFilters = [...genAiFilters, operationsFilter];
			}
		}

		if(this.hasFilter('contactDate')) {
			let contactDate = this.filters.get('contactDate').value;

			if(contactDate && contactDate.length > 0 && contactDate[0] && contactDate[1]) {
				let startContactDateFilter: GenAiPromptFilter = {
					field: 'contactDate',
					value: [ contactDate[0].getTime().toString() ],
					operator: 'GTE'
				};

				let endContactDateFilter: GenAiPromptFilter = {
					field: 'contactDate',
					value: [ contactDate[1].getTime().toString() ],
					operator: 'LTE'
				};

				genAiFilters = [...genAiFilters, startContactDateFilter, endContactDateFilter];
			}
		}

		if(this.hasFilter('metadata')) {
			let metadata = this.filters.get('metadata').value;

			for (const key in metadata) {
				if (metadata[key] === null) {
					delete metadata[key];
				} else {
					let metadataFilter: GenAiPromptFilter = {
						field: key,
						value: [ metadata[key] ],
						operator: 'EQ'
					};

					genAiFilters = [...genAiFilters, metadataFilter];
				}
			}
		}

		if(environment.features.genAiOrganizationFilter) {
			let organizationFilter: GenAiPromptFilter = {
				field: 'organizationAlias',
				value: [this.organizationService.currentOrganization.alias],
				operator: 'EQ'
			}

			genAiFilters = [ ...genAiFilters, organizationFilter ];
		}

		return genAiFilters;
	}

	public segmentData(segmentId: number) {
		return this.genAiChatService.segments.find(segment => segment.id === segmentId);
	}

	public operationData(operationId: number) {
		return this.genAiChatService.operations.find(operation => operation.id === operationId);
	}

	public updateLocalStorage(): void {
		let localStorageIndex = this.organizationService.currentOrganization.alias + '.' + this.interaction?.id;
		localStorage.setItem(localStorageIndex, JSON.stringify(this.messages));
	}

	public resetChat(): void {
		this.messages = [];
		this.updateLocalStorage();
	}

	public handleKeydown(event: KeyboardEvent): void {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			this.sendMessage();
		}
	}

	public adjustTextareaHeight(event: Event): void {
		const textarea = event.target as HTMLTextAreaElement;
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight}px`;

		if (textarea.rows > 8) {
			textarea.rows = 8;
		}
	}

	private hasFilter(filterName: string): boolean {
		return !!this.filters.get(filterName);
	}

	private scrollToBottom(): void {
		try {
			this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
		} catch (err) {
			console.error('Scroll error:', err);
		}
	}

}
