import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseServiceClass } from "./baseService.service";
import { Observable } from "rxjs";
import { OrganizationAiModel } from "app/_models/organization-ai-model";
import { environment } from "../../environments/environment";


@Injectable({providedIn: 'root'})
export class GenAiIntegratorService extends BaseServiceClass<any> {

	public selectedOrganizationAiModel: OrganizationAiModel;

	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v1/genAi');
	}

	public find(id: number): Observable<any> {
		return this.httpService.get(`${environment.genAiUrl}/api/v1/organizationAiModel/${id}`);
	}

	public findByOrganizationAlias(): Observable<any> {
		return this.httpService.get(`${environment.genAiUrl}/api/v1/organizationAiModel`);
	}

	public createOrganizationAiModel(organizationAiModel: OrganizationAiModel): Observable<any> {
		return this.httpService.post(`${environment.genAiUrl}/api/v1/organizationAiModel`, organizationAiModel);
	}

	public updateOrganizationAiModel(organizationAiModelId: number, organizationAiModel: OrganizationAiModel): Observable<any> {
		return this.httpService.put(`${environment.genAiUrl}/api/v1/organizationAiModel/${organizationAiModelId}`, organizationAiModel);
	}

	public findAllAiModels(): Observable<any[]> {
		return this.httpService.get<any[]>(`${environment.genAiUrl}/api/v1/aiModel`);
	}

	public getGenAiTips(organizationAiModelId: number): Observable<any> {
		return this.httpService.get(`${environment.genAiUrl}/api/v1/genAiTips/organizationAiModel/${organizationAiModelId}`);
	}

	public updateGenAiTips(organizationAiModelId: number, genAiTips: any[]): Observable<any> {
		return this.httpService.patch(`${environment.genAiUrl}/api/v1/genAiTips/organizationAiModel/${organizationAiModelId}`, genAiTips);
	}

	public deleteGenAiOrganizationModel(): Observable<any> {
		return this.httpService.delete(`${environment.genAiUrl}/api/v1/organizationAiModel/${this.selectedOrganizationAiModel.id}`);
	}
}
