import { Deserializable } from ".";

export class OrganizationAiModel implements Deserializable {
    id: number;
	name: string;
    organizationAlias: string;
	temperature: string;
    topP: number;
    maxTokens: number;
    awsKnowledgeBaseId: string;
	knowledgeBaseId: string;
    aiModel: any;
    createdAt: Date;
    updatedAt: Date;
    active: boolean;
	promptTemplate: string;
	numberOfResults: number;
	genAiTips: {icon: string, title: string, prompt: string;}[]

    aiModelId: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
