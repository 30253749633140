import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/services';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	public tempRedirectUrl: string;

	constructor(private activatedRoute: ActivatedRoute,
	            private router: Router,
	            public userService: UserService,
	            @Inject(DOCUMENT) private document: Document,
	            private httpClient: HttpClient) {

		if (this.activatedRoute.snapshot.queryParams['token'] &&
			this.activatedRoute.snapshot.queryParams['refreshToken'] &&
			this.activatedRoute.snapshot.queryParams['deviceKey']) {

			let jwtToken = this.activatedRoute.snapshot.queryParams['token'];
			let refreshToken = this.activatedRoute.snapshot.queryParams['refreshToken'];
			let deviceKey = this.activatedRoute.snapshot.queryParams['deviceKey'];
			let webClientId = this.activatedRoute.snapshot.queryParams['webClientId'];

			this.userService.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

			this.userService.updateTokens(jwtToken, refreshToken, deviceKey, webClientId);
			this.getCurrentUser();

			return;
		}

		if (!this.userService.currentUser) {
			this.redirectUser();
		}

		this.router.navigate(['/']);
	}

	ngOnInit(): void {
	}

	public getCurrentUser(): void {
		this.userService.getCurrentUser().subscribe(
			data => {
				this.userService.currentUser = data;

				if (environment.features.enabledChat) {
					this.loadZendeskChat();
				}

				if (localStorage.getItem('userId')) {
					if (localStorage.getItem('userId') != this.userService.currentUser.id) {
						localStorage.clear();
					}
				} else {
					localStorage.clear();
				}

				localStorage.setItem('userId', this.userService.currentUser.id);

				this.router.navigate(['/organizations']);
			}, error => {
				// this.userService.logout();
				console.error(error);
			}
		)
	}

	private loadZendeskChat(): void {
		const head = document.getElementsByTagName('head')[0];
		const script = document.createElement('script', {});
		script.src = 'https://static.zdassets.com/ekr/snippet.js?key=c0937a54-839c-4f3f-ae6a-064ccc21701d';
		script.id = 'ze-snippet';

		head.insertBefore(script, head.firstChild);

		setTimeout(() => {
			let _window = (<any>window);
			_window.zE('messenger', 'loginUser', callback => {
				callback();
			});
			_window.zE('messenger', 'close');
		}, 1000);
	}

	private redirectUser(): void {
		let url = window.location.href;
		let redirectApp = url.slice(0, url.indexOf(this.router.url));

		window.location.href = environment.apps['id'] + '?redirectApp=' + redirectApp + '&redirectUrl=' + this.userService.returnUrl;
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			this.document.location.href = environment.apps['id'];
		}
	}

}
