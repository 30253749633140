import { BrowserModule } from '@angular/platform-browser';
import { forwardRef, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FixedTopHeaderComponent } from './_components/header/fixed-top-header.component';
import { EvoNavigationBarComponent } from './_components/sidenav/evo-navigation-bar.component';
import { SearchInputComponent } from './_components/search-input/search-input.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { IConfig } from 'ngx-mask';
import { TruncatePipe } from './_helpers/pipes/truncate.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CheckIFDate } from './_helpers/pipes/checkIFDate.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SidebarMenuComponent } from './_helpers/sidebar-menu/sidebar-menu.component';
import { DatePipe, DecimalPipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LoginComponent } from './_views/login/login.component';
import { OrganizationsComponent } from './_views/organizations/organizations.component';
import { AuthInterceptor } from '@app/services';
import { EvoSwitchComponent } from './_components/evo-switch/evo-switch.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbDateParserHelper } from './_helpers/ngb-date-parser.helper';
import { NoAccessComponent } from './_views/no-access/no-access.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPtBrPaginatorIntl } from '../assets/i18n/pt-br-paginator-intl';
import { CheckIFDateHour } from './_helpers/pipes/checkIFDateHour.pipe';
import { SharedModule } from '@app/shared';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NgxPowerBiModule } from "ngx-powerbi";
import { CacheInterceptorService } from './_services/cache-interceptor.service';
import {AiAssistantModule} from "@evollo/ai-assistant";
import { MarkdownModule } from "ngx-markdown";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireModule } from "@angular/fire/compat";
import { environment } from "../environments/environment";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		FixedTopHeaderComponent,
		EvoNavigationBarComponent,
		EvoSwitchComponent,
		SidebarMenuComponent,
		LoginComponent,
		OrganizationsComponent,
		NoAccessComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SharedModule,
		NgxPermissionsModule.forRoot(),
		NgxPowerBiModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AiAssistantModule,
		MarkdownModule.forRoot(),
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule
	],
	providers: [
		TruncatePipe,
		CheckIFDate,
		CheckIFDateHour,
		BsModalService,
		BsModalRef,
		DatePipe,
		DecimalPipe,
		TitleCasePipe,
		{provide: NgbDateParserFormatter, useClass: NgbDateParserHelper},
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorService, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SearchInputComponent), multi: true},
		{provide: LOCALE_ID, useValue: 'pt-BR'},
		{provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl()},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private titleCasePipe: TitleCasePipe) {

		const capitalize = (months, month) => {
			return [...months, titleCasePipe.transform(month)];
		}

		localePt[5][1] = localePt[5][2].reduce(capitalize, []);
		localePt[5][2] = localePt[5][2].reduce(capitalize, []);

		registerLocaleData(localePt);
	}
}
