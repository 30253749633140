import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { OrganizationService } from "./organization.service";
import { environment } from "../../environments/environment";
import { UserService } from "./user.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({ providedIn: 'root' })
export class AccessLogService {
	constructor(private router: Router,
	            private organizationService: OrganizationService,
	            private userService: UserService,
				private firestore: AngularFirestore) {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if(!environment.features.accessLogEnabled) {
					return;
				}

				if(event.urlAfterRedirects.includes('/login') || event.urlAfterRedirects.includes('/organization')) {
					return;
				}

				this.registerAccessLog(event.urlAfterRedirects, this.organizationService.currentOrganization.alias);
			});
	}

	public logManualNavigation(url: string): void {
		this.registerAccessLog(url, this.organizationService.currentOrganization.alias);
	}

	private registerAccessLog(url: string, organization: string): void {
		let body = {
			user: this.userService.currentUser.id,
			organization,
			route: url,
			accessDate: new Date()
		}

		this.firestore.collection('access-logs').add(body).then(() => {
			console.log('Log registrado com sucesso:');
		});
	}
}
