import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/models';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SessionConfigService } from '@app/services';
import { UserService } from '@app/services';
import { Organization } from '@app/models';
import { OrganizationService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { HelpDialogComponent } from './_components/help-dialog/help-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpDeskService } from '@app/services';
import { HttpClient } from '@angular/common/http';
import * as config from '../assets/config.json';
import {environment} from "../environments/environment";
import { AiAssistantService } from "@evollo/ai-assistant";
import { AccessLogService } from "./_services/access-log.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	currentUser: User;
	currentToken: string;
	currentOrganization: Organization;
	changePassword = false;
	open: boolean = true;
	modalRef: BsModalRef;

	public permissionsLoaded: boolean = false;
	private config = config;

	public aiEnabled: boolean = environment.features.aiEnabled;
	public genAiUrl: string = environment.genAiUrl;

	constructor(
		public router: Router,
		private ngSelectConfig: NgSelectConfig,
		private userService: UserService,
		public organizationService: OrganizationService,
		public permissionsService: NgxPermissionsService,
		private modal: NgbModal,
		public helpDeskService: HelpDeskService,
		private httpClient: HttpClient,
		translate: TranslateService,
		private aiAssistantService: AiAssistantService,
		private accessLogService: AccessLogService
	) {
		translate.addLangs(['pt-br']);
		translate.setDefaultLang('pt-br');
		translate.use('pt-br');

		this.userService.currentUser$.subscribe(user => {
			this.currentUser = user;
			this.currentOrganization = this.organizationService.currentOrganization;
			this.ngSelectConfig.notFoundText = 'Não encontrado';
			this.currentToken = this.userService.jwtToken;
		});

		this.httpClient.get('../assets/config.json').subscribe(
			data => {
				if(this.config.version != (data as any).version) {
					// window.location.reload(true);
					return;
				}
			});
	}

	ngOnInit(): void {
		this.listenToPermissions();
	}

	public getSidebarStatus(): any {
		return SessionConfigService.getSidebarStatus();
	}

	isLogin() {
		const urlParts = window.location.href.split('/');
		return urlParts[urlParts.length - 1] === 'login';
	}

	logout() {
		this.userService.logout();
		this.router.navigate(['/login']);
	}

	public openHelpDialog(): void {
		const modalRef = this.modal.open(HelpDialogComponent, {});

		modalRef.result.then( data => {
			if(data) {
			}
		});
	}

	private listenToPermissions(): void {
		this.organizationService.permissionsLoaded.pipe(delay(0)).subscribe(
			isLoaded => {
				this.permissionsLoaded = isLoaded;
			});
	}
}
