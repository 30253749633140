import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInfoService } from 'app/_services/file-info.service';

@Component({
	selector: 'evo-upload-file-input',
	templateUrl: './upload-file.component.html',
	styleUrls: ['./upload-file.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => UploadFileComponent),
		multi: true
	}]
})
export class UploadFileComponent implements ControlValueAccessor {
	@Input() rules: string[] = [];
	@Input() accept: string = '*';
	@Input() violatedRules: string[] = [];
	@Input() isMultiple: boolean = true;
	@Input() fileLegend: string = '';
	@Input() showItemLegend: boolean = false;
	@Input() itemLegend: string = 'Clique em avançar para iniciar';

	@Input() uploadProgress: number[] = [0];
	@Input() isProgressBarEnable: boolean = false;
	@Input() isLoading: boolean = false;

	@Output() cancelUpload = new EventEmitter();
	@Output() invalidType = new EventEmitter();
	@Output() existentFile = new EventEmitter();

	public isHover: boolean = false;
	public dropError: string = '';

	@Input() public actionText: string = 'importar';

	onChange: any = () => {
	}
	onTouch: any = () => {
	}

	isDisabled: boolean = false;

	files: any = [];

	set value(val) {
		if (val !== undefined) {
			this.files = val;
			this.onChange(val);
			this.onTouch(val);
		}
	}

	get value() {
		return this.files;
	}

	isFirstWrite: boolean = true;

	constructor(private fileInfoService: FileInfoService) {
	}

	public fileDropped(files: any): void {
		this.writeValue(files);
	}

	writeValue(files: any) {
		if(!this.isUploadFilesValid(files)) {
			return;
		}

		if (this.accept !== '*') {
			if ((files && files.length > 0 && this.accept.indexOf(files[0].type) == -1)) {
				this.invalidType.emit(true);
				return;
			}

			this.invalidType.emit(false);
		}

		if (!files) return;
		this.value = this.isMultiple && !this.isFirstWrite ? [...this.files, ...Array.from(files)] : Array.from(files);
		this.isFirstWrite = false;
	}

	public isUploadFilesValid(files: any): boolean {
		if(!files) {
			return false;
		}

		this.dropError = '';

		if(this.files.length > 0) {
			this.dropError = 'Só é possível enviar um arquivo. Remova o anterior para enviar um novo.';
			return false;
		}

		if(files.length > 1 && !this.isMultiple) {
			this.dropError = 'Só é possível enviar um arquivo.';
			return false;
		}

		if (this.accept !== '*') {
			if (files && files.length > 0) {
				console.log(files[0].type);
				console.log(files[0]);
				if (this.accept.indexOf(files[0].type) == -1 || (files[0].type == '' && files[0].name.indexOf('.url') == -1)) {
					this.dropError = 'Tipo do arquivo inválido.';
					this.invalidType.emit(true);
					return false;
				}

				if (files[0].name) {
					this.fileInfoService.findByOriginalFileName(files[0].name).subscribe((response) => {
						if (response && Object.keys(response).length > 0) {
							this.dropError = 'O arquivo escolhido já existe no Sistema. Selecione outro arquivo para prosseguir com a Importação.'
							this.existentFile.emit(true);
						} else {
							this.existentFile.emit(false);
						}
					});
				}
			}

			this.invalidType.emit(false);
		}

		return true;
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouch = fn;
	}

	removeFile(currFile: File) {
		this.value = this.files.filter(_file => _file.name !== currFile.name);
		this.files = this.files.filter(_file => _file.name !== currFile.name);
	}

	onCancelUpload(currFile: File, index: number) {
		this.removeFile(currFile);
		this.cancelUpload.emit(index);
	}

	setDisabledState(isDisabled: boolean) {
		this.isDisabled = isDisabled;
	}

	resetValueOnClick(HTMLElement: any) {
		if (this.files.length === 0) HTMLElement.target.value = '';
	}

	byteToMb(value: number): any {
		let size = (Math.round((value + Number.EPSILON) / 1000) / 1000);

		if(size < 1) {
			return (size * 1000) + ' KB';
		}

		return size + ' MB';
	}

}
